<template>
  <div class="taskVideo">
    <div
      ref="fullScreenRef"
      class="fullScreen"
      :style="[videoState.hideCursor ? 'cursor: none' : '']"
      @mousemove="onMouseMove"
      @click="onClick"
    >
      <template v-if="showModal">
        <Modal>
          <template v-slot:header><h3>OTSIKKO</h3></template>
          <template v-slot:body>
            <div ref="modalBodyRef">
              <template v-if="showVideoTaskResult">
                <h1>Tulos</h1>
                <p>Tehtävä hyväksytty, hieno suoritus!</p>
              </template>
              <template v-else-if="isContent(testCourseStructure)">
                <h1>{{ testCourseStructure.structureRefData.header }}</h1>
                <div
                  v-html="testCourseStructure.structureRefData.htmlContent"
                ></div>
              </template>
              <template v-else-if="isTask(testCourseStructure)">
                <p>
                  <strong
                    >Tämä on esimerkkitehtävä. Tehtävän pääsee läpi myös
                    väärällä vastauksella.</strong
                  >
                </p>
                <div
                  v-html="
                    testCourseStructure.structureRefData.content.htmlContent
                  "
                ></div>
                <div id="task">
                  <template
                    v-if="
                      testCourseStructure.structureRefData.type ===
                        TaskTypes.SINGLESELECT ||
                      testCourseStructure.structureRefData.type ===
                        TaskTypes.MULTISELECT
                    "
                  >
                    <Select
                      :taskId="testCourseStructure.structureTypeId"
                      :taskType="testCourseStructure.structureRefData.type"
                      :data="
                        JSON.parse(testCourseStructure.structureRefData.data)
                      "
                      :key="testCourseStructure.id"
                    />
                  </template>
                  <template
                    v-else-if="
                      testCourseStructure.structureRefData.type ===
                      TaskTypes.ORDER_THE_LIST
                    "
                  >
                    <OrderTheList
                      :taskId="testCourseStructure.structureTypeId"
                      :taskType="testCourseStructure.structureRefData.type"
                      :data="
                        JSON.parse(testCourseStructure.structureRefData.data)
                      "
                      :key="testCourseStructure.id"
                    />
                  </template>
                </div>
              </template>
              <button class="button raquoAfter" @click="check">Jatka</button>
            </div>
          </template>
        </Modal>
      </template>

      <div class="makeFullscreen">
        <!--<VideoYoutubeSimple :id="taskId" :videoId="videoId"/>-->
        <!--<VideoYoutube :id="taskId" :videoId="videoId"/>-->

        <VideoHandler
          :id="taskId"
          :options="videoOptions"
          :videoType="videoType"
          :videoId="videoId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, watch, shallowRef } from "vue";

import VideoHandler from "./VideoHandler";

import Modal from "@tools/Modal";

import Select from "./Select";
import OrderTheList from "./OrderTheList";

import { CourseStructureHelper } from "@moods/taitaen-core";
//import { isTask, isContent } from '@helpers/CourseStructureHelper'

import { TaskTypes } from "@utils/Training";

import { useAnswer } from "../useAnswer";

import { useFullscreen } from "./useFullscreen";
import useVideoState from "./useVideoState";

import VideoData from "./VideoData";

import { VideoPlayerState } from "@utils/Video";

export default {
  name: "Video",
  components: {
    VideoHandler,
    Modal,
    Select,
    OrderTheList,
  },
  props: ["data", "isTest", "taskId", "taskType"],
  setup(props) {
    const showModal = ref(false);
    const showVideoTaskResult = ref(false);
    const fullScreenRef = ref(null);
    const play = ref(false);
    const modalBodyRef = ref(null);

    const { videoType, videoId, videoOptions, videoData, courseStructures } =
      VideoData();

    const { resetState, videoState } = useVideoState();
    resetState();

    const {
      setFullscreenRef,
      isFullscreenOn,
      toggleFullscreen,
      openFullscreen,
      closeFullscreen,
    } = useFullscreen();
    setFullscreenRef(fullScreenRef);
    videoState.isFullscreenOn = shallowRef(isFullscreenOn);
    videoState.toggleFullscreen = toggleFullscreen;
    videoState.openFullscreen = openFullscreen;
    videoState.closeFullscreen = closeFullscreen;

    const { createRefAnswer, setAnswers, doesTaskExist } =
      useAnswer();
    const answers = createRefAnswer(props.taskId, []);

    onBeforeMount(() => {
      if (doesTaskExist(props.taskId)) {
        //setAnswers(answers, createAnswers(answers, props.taskType));
        setAnswers(answers, createAnswers());
        return;
      }
      if (props.data.options) {
        setAnswers(answers, createAnswers());
      }
    });

    function createAnswers() {
      let obj = [];
      obj.push({
        id: 0,
        value: false,
      });
      return obj;
    }

    const { eraseAnswerByTaskId } =
      useAnswer();

    const testCourseStructure = ref({});

    function check() {
      if (showVideoTaskResult.value) {
        continueVideo();
        //showVideoTaskResult.value = false
        return;
      }
      if (CourseStructureHelper.isTask(testCourseStructure.value)) {
        //let vastaukset = getAnswersWithCourseId(route.params.courseId);
        eraseAnswerByTaskId(testCourseStructure.value.id);
        //answers.value = createAnswers();
        //taskStatuses.value = res.data;
        //showVideoTaskResult.value = true;
        answers.value[0].value = true;
        /*
                sendAnswers(answers)
                .then(res => {
                    taskStatuses.value = res.data;
                    showResult.value = true;
                })
                eraseAllAnswers();
                */

        //return;
      }

      continueVideo();
    }

    function videoStateChanged(state) {
      switch (state) {
        case "started":
          openFullscreen();
          break;

        case "ended":
          closeFullscreen();
          break;
      }
    }

    function continueVideo() {
      showModal.value = false;
      videoState.play = true;
    }

    var timeout;
    function onMouseMove() {
      videoState.hideVideoToolbar = false;
      videoState.hideCursor = false;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        if (videoState.play) {
          videoState.hideVideoToolbar = true;
          //fullScreenRef.value.style.cursor = 'none';
          videoState.hideCursor = true;
        }
      }, 3000);
    }

    function onClick() {
      videoState.hideVideoToolbar = false;
      videoState.hideCursor = false;
    }

    // Show modal if seconds match to courseStructure
    var currentIndex = 0;
    watch(
      () => videoState.progressTimeSeconds,
      () => {
        if (videoData[currentIndex]) {
          if (
            videoState.progressTimeSeconds >=
            videoData[currentIndex].stopTimeSeconds
          ) {
            showCourseStructure(videoData[currentIndex].courseStructureId);
            currentIndex++;
          }
        }
      }
    );

    // Open fullscreen when video started and close when finished
    watch(
      () => videoState.state,
      () => {
        if (videoState.state === VideoPlayerState.STARTED) {
          openFullscreen();
        } else if (videoState.state === VideoPlayerState.ENDED) {
          closeFullscreen();
        }
      }
    );

    function showCourseStructure(id) {
      if (courseStructures && courseStructures[id]) {
        videoState.play = false;
        testCourseStructure.value = courseStructures[id];
        showModal.value = true;
      }
    }

    return {
      videoOptions,
      videoId,
      videoData,
      videoType,

      continueVideo,
      showModal: computed(() => showModal.value),
      play: computed(() => play.value),
      isFullscreenOn: computed(() => isFullscreenOn.value),
      fullScreenRef,
      testCourseStructure: computed(() => testCourseStructure.value),
      TaskTypes,
      check,
      modalBodyRef,

      videoStateChanged,

      onMouseMove,
      onClick,

      videoState,

      showVideoTaskResult: computed(() => showVideoTaskResult.value),

      isTask: CourseStructureHelper.isTask,
      isContent: CourseStructureHelper.isContent,
    };
  },
};
</script>


<style lang="scss">
.hideCursor {
  cursor: none;
}
</style>
